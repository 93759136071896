.header-container {
    flex-direction: row;
    width: 100%;
    background-color: #ebe2e2;
    font-weight: 700;
}

.menuIcon {
    cursor: pointer;
    margin-right: 15px;
}

.header-desktop {
    display:flex;
    height: 80px;
    align-items: center;
}

.header-logo-container {
    display: flex;
    align-items: center;
}

.header-logo-container:hover {
    .header-name{
        color:red;
        transition: .5s;
    }
}

.header-logo-img {
    flex: none;
    width: 50px;
    height: 50px;
    background-color: green;
    margin-left: 25px;
    margin-right: 10px;
    border-radius: 25px;
    cursor: pointer;
}

.header-name {
    text-decoration: none;
    padding: 20px 0 20px;
    color:#333;
}

.spacer {
    flex: 1;
}

.nav-buttons {
    text-decoration: none;
    align-items: center;
    overflow:hidden;
}

.header-item {
    text-decoration: none;
    margin-right: 10px;
}

.header-item:hover {
    text-decoration: none;
    color: #0062b9;
    
    transition: .5s;
}

.active {
    color: rgb(133, 122, 122);
}

.header-item {
    display: inline-block;
    padding: 20px 0 20px;
    vertical-align: middle;
    color: #333;
}

.header-item {
    text-decoration: none;
    transition: color 0.1s, background-color 0.1s;
}
  
