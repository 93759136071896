.home-title {
    font-weight: 700;
    text-align: center;
    padding-top: 10%;
    text-transform: uppercase;
}

.home-details {
    text-align: center;
    padding-top: 2%;
    font-weight: 100;
}

.second-line {
    padding-top: 10px;
}
